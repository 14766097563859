import Vue from "vue";
import Vuex from "vuex";
import Api from "../api";
import { cloneDeep } from "lodash";
import addPatternIdsToIntentPatterns from "../service/addPatternIdsToIntentPatterns";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appTitle: "ICAT",
    alert: {
      message: "This is a message",
      title: "default alert title",
      level: null,
      timeToDisplay: 5000,
      active: false
    },
    intent: {},
    keywords: [],
    intentKeywords: [],
    intentPatterns: [],
    intentPatternNames: [
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight"
    ],
    selectedIntentKeywords: [],
    keywordsPerPage: 30
  },
  mutations: {
    setAlertMessage(state, { title, message, level }) {
      const { alert } = state;
      alert.title = title;
      alert.message = message;
      alert.level = level;
      alert.active = true;
      Vue.set(state, "alert", alert);
    },
    replaceIntentPatternById(state, pattern) {
      const patternIndex = state.intentPatterns.findIndex(
        intentPattern => intentPattern.id == pattern.id
      );
      if (patternIndex == -1)
        return console.error("could not find state pattern to update");
      state.intentPatterns[patternIndex] = pattern;
      Vue.set(state, "intentPatterns", cloneDeep(state.intentPatterns));
    },
    setKeywords(state, keywords) {
      Vue.set(state, "keywords", keywords);
    },
    setIntent(state, intent) {
      Vue.set(state, "intent", intent);
    },
    setIntentKeywords(state, keywords) {
      Vue.set(state, "intentKeywords", keywords);
    },
    setIntentPatterns(state, patterns) {
      Vue.set(state, "intentPatterns", patterns);
    },
    unsetAlert(state) {
      const { alert } = state;
      alert.active = false;
      Vue.set(state, "alert", alert);
    }
  },
  getters: {
    getKeywords(state) {
      return state.keywords;
    },
    getIntent(state) {
      return state.intent;
    },
    getIntentKeywords(state) {
      return state.intentKeywords;
    },
    getIntentPatterns(state) {
      return state.intentPatterns;
    }
  },
  actions: {
    async initializeApp({ commit, state, dispatch }, intentId) {
      const intent = await Api.getIntentById(intentId);
      const intentPatterns = await Api.getIntentPatterns(intentId);
      const intentPatternsWithIds = intentPatterns.map(intentPattern => {
        addPatternIdsToIntentPatterns({
          intentPatternNames: state.intentPatternNames,
          intentPattern
        });
        return intentPattern;
      });
      dispatch("refreshKeywords");
      commit("setIntent", intent);
      commit("setIntentPatterns", intentPatternsWithIds);
    },
    async refreshKeywords({ commit }) {
      const keywords = await Api.getExistingKeywords();
      commit("setKeywords", keywords);
    },
    async refreshPatterns({ commit, state }) {
      const patterns = await Api.getIntentPatterns(state.intent.id);
      commit("setIntentPatterns", patterns);
    },
    async addNewKeyword({ commit, dispatch, state }, keyword) {
      const label = keyword.meta.label.toLowerCase();
      const patternId = keyword.meta.patternId;
      try {
        const newKeyword = await Api.createNewKeyword(keyword);
        const updatedPattern = await Api.updateIntentPatterns(
          newKeyword,
          label,
          patternId
        );

        addPatternIdsToIntentPatterns({
          intentPatternNames: state.intentPatternNames,
          intentPattern: updatedPattern
        });
        setTimeout(() => dispatch("refreshKeywords"), 250);

        commit("replaceIntentPatternById", updatedPattern);
      } catch (error) {
        const alert = {
          message: "Keyword Already Exists",
          title: "Oh No!",
          level: "danger",
          timeToDisplay: 5000,
          active: false
        };
        commit("setAlertMessage", alert);
      }
    },
    async addExistingKeyword({ commit, state }, patternKeyword) {
      const label = patternKeyword.meta.label.toLowerCase();
      const patternId = patternKeyword.meta.patternId;
      const updatedPattern = await Api.updateIntentPatterns(
        patternKeyword,
        label,
        patternId
      );
      addPatternIdsToIntentPatterns({
        intentPatternNames: state.intentPatternNames,
        intentPattern: updatedPattern
      });

      commit("replaceIntentPatternById", updatedPattern);
    },
    async createPattern({ commit, dispatch, state }) {
      const newPattern = await Api.createIntentPattern(state.intent.id);
      const updatedPattern = cloneDeep(state.intentPatterns);
      newPattern.keywordByKeywordIdOne = { patternId: newPattern.id };
      updatedPattern.push(newPattern);
      setTimeout(() => dispatch("refreshPatterns"), 250);
      commit("setIntentPatterns", updatedPattern);
    },
    async deletePattern({ commit, state }, patternId) {
      const deletedPattern = await Api.deleteIntentPattern(patternId);
      addPatternIdsToIntentPatterns({
        intentPatternNames: state.intentPatternNames,
        intentPattern: deletedPattern
      });
      const updatedPatterns = await Api.getIntentPatterns(state.intent.id);
      commit("replaceIntentPatternById", deletedPattern);
      commit("setIntentPatterns", updatedPatterns);
    },
    async deletePatternKeyword({ commit, state }, { label, patternId }) {
      const pattern = await Api.deleteKeywordFromPattern(label, patternId);
      addPatternIdsToIntentPatterns({
        intentPatternNames: state.intentPatternNames,
        intentPattern: pattern
      });
      commit("replaceIntentPatternById", pattern);
    },
    setMessage({ commit, state, dispatch }, alert) {
      commit("setAlertMessage", alert);
      setTimeout(() => dispatch("unsetMessage"), state.alert.timeToDisplay);
    },
    unsetMessage({ commit }) {
      commit("unsetAlert");
    }
  }
});
