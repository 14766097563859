<template>
  <section class="d-flex flex-column bg-white">
    <div class="container d-flex flex-column flex-grow-1 p-5">
      <div class="d-flex align-items-center justify-content-between">
        <h2 class="section-title mt-0 mb-2">
          {{ intentName }}
        </h2>
        <div class="add-new-btn">
          <button
            class="btn btn-text d-flex align-items-center px-0"
            @click="handlePatternCreate()"
            data-test-button-add-pattern
          >
            <span class="material-icons mx-2">add_circle</span>
            <span class="">Add Pattern</span>
          </button>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row pt-4 pb-5">
        <div class="keyword-list flex-colum flex-wrap flex-grow-1 mb-5 mb-md-0">
          <div
            v-for="(pattern, index) in filteredIntentPatterns"
            :key="index"
            class="keyword-pattern d-grid py-2"
            :class="{
              active: editMode && setPatternActive(pattern)
            }"
            data-test-intent-pattern
          >
            <div
              v-for="(keyword, index) in pattern"
              :key="index"
              class="d-flex px-4 py-1"
            >
              <a
                href=""
                class="keyword p-1 fs-6"
                @click="handleNavigateToKeywordConfig(keyword)"
                :data-test-link-keyword="keyword.name"
                >{{ keyword.name }}</a
              >
            </div>
            <span
              class="
                pattern-actions
                d-flex
                align-items-center
                justify-content-center
                ms-auto
                px-1
              "
            >
              <button
                v-if="setPatternActive(pattern) && editMode"
                type="button"
                class="
                  edit-btn
                  btn btn-text
                  text-white
                  d-flex
                  align-items-center
                  px-0
                "
                @click="exitEditMode(pattern)"
                data-test-button-exit-edit-mode
              >
                <span class="material-icons fs-6">close</span>
              </button>
              <button
                v-else
                type="button"
                class="
                  edit-btn
                  btn btn-text
                  link-secondary
                  d-flex
                  align-items-center
                  px-0
                "
                @click="switchToEditMode(pattern)"
                data-test-toggle-edit-mode
              >
                <span class="material-icons fs-4">edit_note</span>
              </button>
              <button
                type="button"
                class="
                  edit-btn
                  btn btn-text
                  link-secondary
                  d-flex
                  align-items-center
                  px-1
                "
                :class="{
                  'text-white': editMode && setPatternActive(pattern)
                }"
                @click="handlePatternDelete(pattern)"
                data-test-button-delete-pattern
              >
                <span class="material-icons fs-5">delete</span>
              </button>
            </span>
          </div>
        </div>
        <keyword-edit
          v-show="editMode"
          :keywords="keywords"
          :editingKeywords="editingKeywords"
          :editing="editMode"
          :patternId="editingPatternId"
          @addNewKeyword="addNewKeyword"
          @addExistingKeyword="addExistingKeyword"
          @deleteKeyword="deleteKeyword"
        />
      </div>
      <!-- <pager
        :records="filteredIntentPatterns.length"
        :per-page="perPage"
        class=""
        @pageNumberChanged="handlePageNumberChanged"
      /> -->
    </div>
  </section>
</template>

<script>
// import Pager from "../atoms/Pager.vue";
import { keywordConfigurationUtility } from "../../service/keywordConfigurationUtility";
import KeywordEdit from "../organisims/KeywordEdit.vue";

export default {
  name: "KeywordManager",
  components: {
    // Pager,
    KeywordEdit
  },
  props: {
    keywords: Array,
    intentPatterns: Array
  },
  data() {
    return {
      editMode: false,
      editingKeywords: [],
      editingPatternId: null
    };
  },
  computed: {
    intentName() {
      if (this.$route.query.intentId) {
        return this.$route.query.intentId;
      } else {
        return "Intent Name";
      }
    },
    endIndex() {
      const endIndex = this.startIndex + this.perPage;
      if (endIndex >= this.filteredIntentPatterns.length) {
        return this.filteredIntentPatterns.length;
      } else {
        return endIndex;
      }
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    filteredIntentPatterns() {
      const patterns = this.intentPatterns;
      const keywordNumbers = [
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight"
      ];
      return patterns.map(pattern => {
        const patternId = pattern.id;
        const keywords = [];
        for (let keywordNumber of keywordNumbers) {
          let keyword = pattern[`keywordByKeywordId${keywordNumber}`];
          if (!keyword) {
            keyword = {
              id: null,
              name: null,
              patternId
            };
          }
          keywords.push(keyword);
        }
        return keywords.filter(keyword => keyword);
      });
    }
  },
  methods: {
    handlePageNumberChanged(pageNumber) {
      this.currentPage = pageNumber;
    },
    handleSearchInput(searchInput) {
      this.searchText = searchInput;
      this.currentPage = 1;
    },
    addNewKeyword(newKeyword) {
      const existingKeyword = this.keywords.find(
        keyword => keyword.id == newKeyword.id
      );
      if (existingKeyword) {
        const alert = {
          message: "Keyword Already Exists",
          title: "Oh No!",
          level: "danger",
          timeToDisplay: 5000,
          active: false
        };
        this.$store.dispatch("setMessage", alert);
      } else {
        this.editingKeywords.push(newKeyword);
        this.$store.dispatch("addNewKeyword", newKeyword);
      }
    },
    addExistingKeyword(keyword) {
      this.$emit("addExistingKeyword", keyword);
    },
    deleteKeyword(label, patternId) {
      // if (patternId == this.editingPatternId) {

      // }
      this.$store.dispatch("deletePatternKeyword", { label, patternId });
    },
    handleNavigateToKeywordConfig(keyword) {
      keywordConfigurationUtility(keyword.id);
    },
    handlePatternCreate() {
      this.$store.dispatch("createPattern");
      // const newPattern = this.$store.getters.getIntentPatterns;
      // this.setEditingKeywords(newPattern);
      // this.editMode = true;
      // console.log("new pattern", newPattern);
    },
    handlePatternDelete(pattern) {
      console.log("delete pattern", pattern);
      this.exitEditMode();
      this.$store.dispatch("deletePattern", pattern[0].patternId);
    },
    switchToEditMode(pattern) {
      this.editMode = true;
      this.setEditingKeywords(pattern);
    },
    exitEditMode() {
      this.editMode = false;
      this.editingKeywords = [];
      this.editingPatternId = null;
    },
    setEditingKeywords(pattern) {
      this.editingKeywords = pattern;
      this.editingPatternId = pattern[0]?.patternId;
      console.log("setEditingKeywords", pattern, this.editingKeywords);
    },
    setPatternActive(patterns) {
      for (let selectedPattern in patterns) {
        const pattern = patterns[selectedPattern];
        console.log("**** pattern", pattern, this.editingPatternId);
        if (pattern.patternId == this.editingPatternId) {
          // this.editingPatternId = pattern.patternId
          return true;
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    intentPatterns: {
      deep: true,
      handler: function() {
        if (this.editMode && this.editingPatternId) {
          const pattern = this.intentPatterns.find(
            intentPattern => intentPattern.id == this.editingPatternId
          );
          if (!pattern) return;
          const keywords = [];
          for (let keywordNumber of this.$store.state.intentPatternNames) {
            const keyword = pattern[`keywordByKeywordId${keywordNumber}`];
            keywords.push(keyword);
          }
          this.setEditingKeywords(keywords);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.section-title {
  padding-bottom: 0.5em;
  position: relative;

  &::after {
    background: var(--table-border-color, #eee);
    content: "";
    // display: block;
    height: 2px;
    // max-width: 25vw;
    width: 100%;
    margin: 1rem auto 0;
  }
}

.add-menu {
  --mdc-icon-button-size: 30px;
  --mdc-icon-size: 24px;
}
.create-menu {
  --mdc-icon-button-size: 30px;
  --mdc-icon-size: 19px;
}

.edit-btn {
  &:focus,
  &:active {
    background: var(--color-link);
    color: #fff;
  }
}

.action-bar {
  display: none !important;
  position: sticky;
}

.action-bar__actions {
  --mdc-text-field-outlined-idle-border-color: #0000;
  --mdc-text-field-focused-label-color: var(--bs-blue);
  background: #fff;
  border-radius: 4px;
  // box-shadow: 2px 2px 20px #0002;
  border: 1px solid #ccc;
  padding-right: 0.5rem;
}

.keyword-list {
  display: flex;

  @supports (gap: 1px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, auto));
    gap: 1.5rem;
    align-content: start;
    grid-auto-flow: dense;
  }
}

.keyword-pattern {
  grid-template-columns: 1fr 65px;
  align-content: baseline;
  // grid-template-rows: repeat(3, 1fr);
  background: var(--color-grey-light);
  border-radius: 4px;
  min-height: 55px;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;

  > * {
    grid-column: 1/2;
  }

  &:hover,
  &:focus {
    box-shadow: inset 0 0 10px 1px #0001, -8px 15px 15px 0 #0001;

    .pattern-actions {
      opacity: 1;
    }
  }

  &.active {
    background: var(--color-link);
    // grid-column: 2/2;
    // grid-row: 1/1;

    .keyword {
      color: #fff;
    }

    .pattern-actions {
      opacity: 1;
    }
  }
}

.keyword {
  color: var(--color-link);
  border-radius: 4px;
  font-family: monospace;
  font-weight: 500;
  line-height: 1.5;
  // justify-content: center;
  // justify-self: center;
  align-items: center;
  // align-self: center;
  // text-align: center;
  text-decoration: none;
  transition: 0.3s ease;

  // &:nth-last-child(n + 2),
  // &:nth-last-child(n + 2) ~ a {
  //   border-radius: 0;

  //   &:first-child {
  //     border-radius: 4px 0 0 4px;
  //   }
  //   &:last-child {
  //     border-radius: 0 4px 4px 0;
  //   }
  // }

  &:hover,
  &:focus {
    background: var(--color-link);
    // border-radius: 0 4px 4px 0;
    color: var(--color-grey-light);
  }

  // @media (min-width: 37em) {
  //   flex-basis: 25%;
  // }

  // @media (min-width: 56em) {
  //   flex-basis: 15%;
  // }
}

.pattern-actions {
  grid-column: 2/2;
  grid-row: 1/3;
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 0.3s ease;
}
</style>
