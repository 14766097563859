<template>
  <div
    class="add-menu menu dropdown d-flex"
    :class="{ 'pos-r': isPositionedRight }"
    data-test-action-menu
  >
    <!-- <icon-button
      v-if="!menuIsEmpty"
      id="domainDropdownMenuButton"
      :icon="handleIconClass"
      class="icon-sm mx-auto"
      @click="handleShowMenu"
    /> -->
    <button
      class="btn btn-text d-flex align-items-center p-0"
      @click="handleShowMenu"
      data-test-toggle-keyword-menu
    >
      <span class="toggle material-icons fs-5">
        playlist_add
      </span>
    </button>
    <ul
      v-show="menuIsActive"
      id="keywordMenu"
      class="list list__dropdown-menu"
      aria-labelledby="domainDropdownMenuButton"
      data-test-menu-keywords
    >
      <li
        v-for="item in filteredSortedSelectables"
        :key="item.id"
        class="dropdown-item"
        @click="handleAddSelectable(item)"
        :data-test-dropdown-item="item.name"
      >
        <a href="#">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { sortBy } from "lodash";

export default {
  name: "ActionMenu",
  components: {},
  props: {
    value: String,
    selectables: Array,
    meta: Object
  },
  data() {
    return {
      menuIsActive: false
    };
  },
  computed: {
    filteredSortedSelectables() {
      const filteredSelectables = this.selectables.filter(
        selectable => selectable.name != this.value
      );
      return sortBy(filteredSelectables, [
        selectable => selectable.name.toLowerCase()
      ]);
    },
    menuIsEmpty() {
      if (this.selectables.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    handleIconClass() {
      if (this.menuIsActive) {
        return "clear";
      } else {
        return "add";
      }
    },
    isPositionedRight() {
      return this.positionClass == "right";
    }
  },
  methods: {
    handleAddSelectable(item) {
      const data = {
        id: item.id,
        meta: this.meta
      };
      if (this.selectables.length == 1) {
        this.menuIsActive = false;
      }
      this.$emit("menuItemSelected", data);
      this.handleCloseMenu(data);
    },
    handleShowMenu() {
      this.menuIsActive = !this.menuIsActive;
    },
    handleCloseMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.menuIsActive = false;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleCloseMenu);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleCloseMenu);
  }
};
</script>
<style lang="scss">
.list {
  &__dropdown-menu {
    background: #fff;
    box-shadow: 2px 2px 30px 0 rgba(#000, 0.4);
    border-radius: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    margin-bottom: 0;
    height: 300px;
    min-width: 240px;
    overflow: auto;
    position: absolute;
    text-align: left;
    z-index: 100;
  }
}

.dropdown .toggle {
}

.dropdown-item {
  white-space: initial;

  a {
    text-decoration: none;
  }
  &.active,
  &:active,
  &:hover,
  &:focus {
    background-color: #0000;

    a {
      color: initial;
    }
  }
}

.pos-r .list__dropdown-menu {
  right: 1rem;
  @media (min-width: 540px) {
    right: 1rem;
  }
}

.input-create-keyword {
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
}
</style>
