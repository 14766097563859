var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"keyword-edit flex-grow-1 ms-md-4"},_vm._l((_vm.$store.state.intentPatternNames),function(label){return _c('div',{key:label,staticClass:"edit-item d-flex mb-3",class:{ active: _vm.editingKeywordsList[label].existing },attrs:{"data-test-keyword-edit":""}},[_c('div',{staticClass:"form-floating flex-grow-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editingKeywordsList[label].name),expression:"editingKeywordsList[label].name"}],staticClass:"form-control",class:{ active: _vm.editingKeywordsList[label].existing },attrs:{"id":"keywordInput","type":"text","autocomplete":"off","data-test-input-keyword":""},domProps:{"value":(_vm.editingKeywordsList[label].name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.editingKeywordsList[label], "name", $event.target.value)},function($event){_vm.handleNewKeywordInput(
            $event.target.value.replaceAll(' ', '_'),
            label
          )}]}}),_c('label',{staticClass:"fs-7",attrs:{"for":"keywordInput"}},[_vm._v("Keyword "+_vm._s(label))])]),_c('div',{staticClass:"btn-group px-2",attrs:{"hidden":!_vm.editing,"data-test-keyword-actions":""}},[(_vm.editingKeywordsList[label].name)?_c('button',{staticClass:"btn btn-text flex-grow-0 d-flex align-items-center me-2 p-0",attrs:{"data-test-button-keyword-delete":""},on:{"click":function($event){return _vm.handleKeywordDelete(label, _vm.patternId)}}},[_c('span',{staticClass:"material-icons fs-6"},[_vm._v("delete_forever")])]):_vm._e(),(
          _vm.isExistingKeyword(_vm.editingKeywordsList[label].name) ||
            !_vm.editingKeywordsList[label].name
        )?_c('action-menu',{staticClass:"pos-r",attrs:{"icon":"add","value":_vm.editingKeywordsList[label].name,"selectables":_vm.keywords,"meta":{ label, patternId: _vm.patternId }},on:{"menuItemSelected":_vm.handleAddExistingKeyword}}):_vm._e(),(
          !_vm.isExistingKeyword(_vm.editingKeywordsList[label].name) &&
            _vm.editingKeywordsList[label].name
        )?_c('button',{staticClass:"btn btn-text flex-grow-0 d-flex align-items-center ms-1 p-0",class:[
          {
            'link-secondary': _vm.editingKeywordsList[label].existing,
            'link-primary active': !_vm.editingKeywordsList[label].existing
          }
        ],attrs:{"disabled":_vm.newKeyword.length == 0,"data-test-button-add-keyword":""},on:{"click":_vm.handleAddNewKeyword}},[_c('span',{staticClass:"material-icons fs-6"},[_vm._v("check")])]):_vm._e()],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }