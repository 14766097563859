import gql from "graphql-tag";
import apollo from "./apolloClient";

const suffixEnv = process.env.VUE_APP_NODE_ENV === "production" ? "" : "_test";
const Api = {
  async getExistingKeywords() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetExistingKeywords {
          keywords${suffixEnv} {
            id
            name
          }
        }
      `
    });
    return response.data[`keywords${suffixEnv}`];
  },
  async getIntentById(intentId) {
    console.log("getIntentById", intentId);
    const intent = await apollo.query({
      query: gql`
        query GetIntentById {
          intents${suffixEnv}(where: {id: {_eq: "${intentId}"}}) {
            id
            name
            pattern
          }
        }
      `
    });

    return intent.data[`intents${suffixEnv}`];
  },
  async getIntentPatterns(intentId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetIntentPatterns {
          intent_patterns${suffixEnv}(where: {intent_id: {_eq: "${intentId}"}, deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
            id
            intent_id
            keywordByKeywordIdOne {
              id
              name
            }
            keywordByKeywordIdTwo {
              id
              name
            }
            keywordByKeywordIdThree {
              id
              name
            }
            keywordByKeywordIdFour {
              id
              name
            }
            keywordByKeywordIdFive {
              id
              name
            }
            keywordByKeywordIdSix {
              id
              name
            }
            keywordByKeywordIdSeven {
              id
              name
            }
            keywordByKeywordIdEight {
              id
              name
            }
          }
        }      
      `
    });
    return response.data[`intent_patterns${suffixEnv}`];
  },
  async updateIntentPatterns(patternKeyword, label, patternId) {
    console.log(
      "patternKeyword",
      patternKeyword,
      "label",
      label,
      "patternId",
      patternId
    );
    const response = await apollo.mutate({
      mutation: gql`
      mutation UpdatePatterns {
        update_intent_patterns${suffixEnv}(where: {id: {_eq: "${patternId}"}}, _set: {keyword_id_${label}: "${patternKeyword.id}"}) {
          returning {
            id
            intent_id
            keywordByKeywordIdOne {
              id
              name
            }
            keywordByKeywordIdTwo {
              id
              name
            }
            keywordByKeywordIdThree {
              id
              name
            }
            keywordByKeywordIdFour {
              id
              name
            }
            keywordByKeywordIdFive {
              id
              name
            }
            keywordByKeywordIdSix {
              id
              name
            }
            keywordByKeywordIdSeven {
              id
              name
            }
            keywordByKeywordIdEight {
              id
              name
            }
          }
        }
      }
    `
    });
    return response.data[`update_intent_patterns${suffixEnv}`].returning[0];
  },
  async createNewKeyword(keyword) {
    const response = await apollo.mutate({
      mutation: gql`
      mutation CreateKeyword {
        insert_keywords${suffixEnv}_one(object: {id: ${keyword.id}, name: ${keyword.id}}) {
          id
          name
        }
      }
    `
    });
    console.log(
      "new keyword created",
      response.data[`insert_keywords${suffixEnv}_one`]
    );
    return response.data[`insert_keywords${suffixEnv}_one`];
  },
  async createIntentPattern(intentId) {
    const response = await apollo.mutate({
      mutation: gql`
      mutation CreateIntentPattern {
        insert_intent_patterns${suffixEnv}_one(object: {intent_id: "${intentId}"}) {
          id
          intent_id
          keywordByKeywordIdOne {
            id
            name
          }
          keywordByKeywordIdTwo {
            id
            name
          }
          keywordByKeywordIdThree {
            id
            name
          }
          keywordByKeywordIdFour {
            id
            name
          }
          keywordByKeywordIdFive {
            id
            name
          }
          keywordByKeywordIdSix {
            id
            name
          }
          keywordByKeywordIdSeven {
            id
            name
          }
          keywordByKeywordIdEight {
            id
            name
          }
        }
      }    
    `
    });
    return response.data[`insert_intent_patterns${suffixEnv}_one`];
  },
  async deleteIntentPattern(patternId) {
    const response = await apollo.mutate({
      mutation: gql`
      mutation DeleteIntentPattern {
        update_intent_patterns${suffixEnv}(where: {id: {_eq: ${patternId}}}, _set: {deleted_at: "now()"}) {
          returning {
            id
            deleted_at
          }
        }
      }    
    `
    });
    console.log(
      "pattern deleted",
      response.data[`update_intent_patterns${suffixEnv}`].returning[0]
    );
    return response.data[`update_intent_patterns${suffixEnv}`].returning[0];
  },
  async deleteKeywordFromPattern(label, patternId) {
    const response = await apollo.mutate({
      mutation: gql`
      mutation DeleteKeywordFromPattern {
        update_intent_patterns${suffixEnv}(where: {id: {_eq: "${patternId}"}}, _set: {keyword_id_${label.toLowerCase()}: null}) {
          returning {
            id
            intent_id
            keywordByKeywordIdOne {
              id
              name
            }
            keywordByKeywordIdTwo {
              id
              name
            }
            keywordByKeywordIdThree {
              id
              name
            }
            keywordByKeywordIdFour {
              id
              name
            }
            keywordByKeywordIdFive {
              id
              name
            }
            keywordByKeywordIdSix {
              id
              name
            }
            keywordByKeywordIdSeven {
              id
              name
            }
            keywordByKeywordIdEight {
              id
              name
            }
          }
        }
      }
    `
    });
    console.log(
      "pattern keyword deleted",
      response.data[`update_intent_patterns${suffixEnv}`].returning[0]
    );
    return response.data[`update_intent_patterns${suffixEnv}`].returning[0];
  }
};

export default Api;
