import Vue from "vue";
import VueRouter from "vue-router";
import AppView from "../components/AppView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AppView",
    component: AppView
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
