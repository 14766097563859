<template>
  <keyword-manager
    :keywords="keywords"
    :intent-patterns="intentPatterns"
    @addExistingKeyword="addExistingKeyword"
  />
</template>

<script>
import KeywordManager from "./views/KeywordManager.vue";

export default {
  name: "AppView",
  components: { KeywordManager },
  computed: {
    keywords() {
      return this.$store.getters.getKeywords;
    },
    intentPatterns() {
      return this.$store.getters.getIntentPatterns;
    }
  },
  methods: {
    addExistingKeyword(keyword) {
      this.$store.dispatch("addExistingKeyword", keyword);
    }
  }
};
</script>

<style lang="scss"></style>
