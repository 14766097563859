function addPatternIdsToIntentPatterns({ intentPatternNames, intentPattern }) {
  intentPatternNames.forEach(intentPatternName => {
    try {
      intentPattern[`keywordByKeywordId${intentPatternName}`].patternId =
        intentPattern.id;
    } catch (error) {
      // console.error(error);
    }
  });
}

export default addPatternIdsToIntentPatterns;
