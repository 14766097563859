<template>
  <div class="keyword-edit flex-grow-1 ms-md-4">
    <div
      v-for="label in $store.state.intentPatternNames"
      :key="label"
      class="edit-item d-flex mb-3"
      :class="{ active: editingKeywordsList[label].existing }"
      data-test-keyword-edit
    >
      <div class="form-floating flex-grow-1">
        <input
          v-model="editingKeywordsList[label].name"
          id="keywordInput"
          type="text"
          class="form-control"
          :class="{ active: editingKeywordsList[label].existing }"
          autocomplete="off"
          @input="
            handleNewKeywordInput(
              $event.target.value.replaceAll(' ', '_'),
              label
            )
          "
          data-test-input-keyword
        />
        <label class="fs-7" for="keywordInput">Keyword {{ label }}</label>
      </div>
      <div class="btn-group px-2" :hidden="!editing" data-test-keyword-actions>
        <button
          v-if="editingKeywordsList[label].name"
          class="btn btn-text flex-grow-0 d-flex align-items-center me-2 p-0"
          @click="handleKeywordDelete(label, patternId)"
          data-test-button-keyword-delete
        >
          <span class="material-icons fs-6">delete_forever</span>
        </button>
        <action-menu
          v-if="
            isExistingKeyword(editingKeywordsList[label].name) ||
              !editingKeywordsList[label].name
          "
          icon="add"
          :value="editingKeywordsList[label].name"
          :selectables="keywords"
          :meta="{ label, patternId: patternId }"
          class="pos-r"
          @menuItemSelected="handleAddExistingKeyword"
        />

        <button
          v-if="
            !isExistingKeyword(editingKeywordsList[label].name) &&
              editingKeywordsList[label].name
          "
          class="btn btn-text flex-grow-0 d-flex align-items-center ms-1 p-0"
          :class="[
            {
              'link-secondary': editingKeywordsList[label].existing,
              'link-primary active': !editingKeywordsList[label].existing
            }
          ]"
          :disabled="newKeyword.length == 0"
          @click="handleAddNewKeyword"
          data-test-button-add-keyword
        >
          <span class="material-icons fs-6">check</span>
        </button>
      </div>
    </div>
    <!-- <div class="form-floating">
      <select
        class="form-select select"
        id="keywordSelect"
        aria-label="select keyword"
        @change="handleKeywordSelect($event.target)"
      >
        <option
          v-for="keyword in keywords"
          :key="keyword.name"
          :value="keyword.name"
          :selected="isSelected"
          class="select-option"
          data-test-keyword-select-option
          >{{ keyword.name }}</option
        >
      </select>
      <label for="floatingSelect">select keywords</label>
    </div> -->
  </div>
</template>
<script>
import ActionMenu from "../molecules/ActionMenu.vue";
export default {
  name: "KeywordEdit",
  components: { ActionMenu },
  props: {
    keywords: Array,
    editingKeywords: Array,
    editing: Boolean,
    patternId: Number
  },
  data() {
    return {
      isSelected: false,
      newKeyword: {},
      editingKeywordsList: {
        One: { id: null, name: "", existing: true },
        Two: { id: null, name: "", existing: true },
        Three: { id: null, name: "", existing: true },
        Four: { id: null, name: "", existing: true },
        Five: { id: null, name: "", existing: true },
        Six: { id: null, name: "", existing: true },
        Seven: { id: null, name: "", existing: true },
        Eight: { id: null, name: "", existing: true }
      }
    };
  },
  computed: {},
  methods: {
    resetEditingKeywordsList() {
      console.log("resetting editing keywords list");
      this.editingKeywordsList.One = this.editingKeywords[0] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Two = this.editingKeywords[1] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Three = this.editingKeywords[2] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Four = this.editingKeywords[3] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Five = this.editingKeywords[4] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Six = this.editingKeywords[5] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Seven = this.editingKeywords[6] || {
        id: null,
        name: null,
        existing: true
      };
      this.editingKeywordsList.Eight = this.editingKeywords[7] || {
        id: null,
        name: null,
        existing: true
      };
    },
    handleNewKeywordInput(keyword, label) {
      const cleanKeyword = keyword.replaceAll(" ", "_");
      this.newKeyword = {
        id: cleanKeyword,
        meta: {
          label: label,
          patternId: this.patternId
        }
      };
      console.log(this.newKeyword, cleanKeyword);
    },
    handleAddNewKeyword() {
      this.$emit("addNewKeyword", this.newKeyword);
    },
    handleAddExistingKeyword(keyword) {
      this.$emit("addExistingKeyword", keyword);
    },
    handleKeywordDelete(label, patternId) {
      this.$emit("deleteKeyword", label, patternId);
    },
    isExistingKeyword(keyword) {
      const existingKeywords = this.$store.getters.getKeywords.find(
        existingKeyword => existingKeyword.name == keyword
      );
      if (existingKeywords) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    editingKeywords: {
      deep: true,
      handler: "resetEditingKeywordsList"
    }
  }
};
</script>
<style lang="scss">
.keyword-edit {
  flex-basis: 35vw;

  .form-control {
    border: 0;

    .active {
      box-shadow: 0 0 10px 0 var(--color-grey);
    }
  }
  @media screen and (min-width: 768px) {
    max-width: 20rem;
  }
}

.edit-item {
  border: 1px solid var(--color-grey-400);
  border-radius: var(--input-border-radius);
}

[disabled] {
  pointer-events: none;
  opacity: 0.4;
}
</style>
